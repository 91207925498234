import Api from '@/api/index';
import { SuperVue } from '@/mixins/SuperVue';
import { Options } from 'vue-class-component';

@Options({
  components: { }
})

export default class PatentResult extends SuperVue {

}
